/**
 * This callback type is called `requestCallback` and is displayed as a global symbol.
 *
 * @callback onClickCallback
 */


/**
 * Attach a checkbox within a formfield
 *
 * @param {String} chkId ID of an element
 * @param {String} ffId ID of a formfield
 */
function attachCheckboxById(chkId, ffId) {
  const elem = mdc.checkbox.MDCCheckbox.attachTo(document.getElementById(chkId))
  const formField = mdc.formField.MDCFormField.attachTo(document.getElementById(ffId))
  formField.input = elem
}

/**
 * Attach a radio within a formfield
 *
 * @param {String} chkId ID of an element
 * @param {String} ffId ID of a formfield
 */
function attachRadioById(radioId, ffId) {
  const elem = mdc.radio.MDCRadio.attachTo(document.getElementById(radioId))
  const formField = mdc.formField.MDCFormField.attachTo(document.getElementById(ffId))
  formField.input = elem
}

/**
 * Attach a ripple click effect by ID and opens a url
 *
 * @param {String} id ID of an element
 * @param {String} url URL
 */
function attachRippleByIdWithUrl(id, url) {
  const elem = document.getElementById(id)
  elem.onclick = () => {
    window.location.href = url
  }
  mdc.ripple.MDCRipple.attachTo(elem)
}

/**
 * Attach a ripple click effect by ID and opens a url
 *
 * @param {String} id ID of an element
 * @param {onClickCallback} fnc URL
 */
function attachRippleByIdWithFunction(id, fnc) {
  const elem = document.getElementById(id)
  elem.onclick = fnc
  mdc.ripple.MDCRipple.attachTo(elem)
}

/**
 * Attach a ripple click effect by Class Name and opens a url
 *
 * @param {String} className Class name of an element
 * @param {onClickCallback} fnc URL
 */
function attachRippleByClassWithFunction(className, fnc) {
  document.getElementsByClassName(className).forEach(function(elem){
  elem.onclick = fnc
  mdc.ripple.MDCRipple.attachTo(elem)
  })
}

/**
 * Attach a ripple click effect by ID
 *
 * @param {String} id ID of an element
 */
function attachRippleById(id) {
  mdc.ripple.MDCRipple.attachTo(document.getElementById(id))
}

/**
 * Attach a ripple click effect by selector
 *
 * @param {String} selector selector
 */
function attachRippleBySelector(selector) {
  mdc.ripple.MDCRipple.attachTo(document.querySelector(selector))
}

/**
 * Attach a ripple click effect by a selector
 *
 * @param {String} selector selector
 */
function attachRippleToNav(selector) {
  let elements = document.querySelectorAll(selector)
  for (let i = 0, len = elements.length; i < len; i++) {
    mdc.ripple.MDCRipple.attachTo(elements[i])
  }
}

/**
 * Attach a list by a selector
 *
 * @param {String} selector selector
 */
function attachListElements(selector) {
  let elements = document.querySelectorAll(selector)
  for (let i = 0, len = elements.length; i < len; i++) {
    mdc.list.MDCList.attachTo(elements[i])
  }
}

/**
 * Attach a linar progress by ID
 *
 * @param {String} id ID of an element
 */
function attachLinearProgressById(id) {
  mdc.linearProgress.MDCLinearProgress.attachTo(document.getElementById(id))
}

/**
 * Attach a textfield by ID
 *
 * @param {String} id ID of an element
 */
function attachTextfieldById(id) {
  mdc.textField.MDCTextField.attachTo(document.getElementById(id))
}

/**
 * Attach a select by ID
 *
 * @param {String} id ID of an element
 */
function attachSelectById(id) {
  return mdc.select.MDCSelect.attachTo(document.getElementById(id))
}

/**
 * Attach a select by ID with an hidden field for submit selected value
 *
 * @param {String} id ID of an element
 * @param {String} hiddenFieldId ID of the hidden
 */
function attachSelectByIdAndHiddenField(id, hiddenFieldId) {
  const select = mdc.select.MDCSelect.attachTo(document.getElementById(id))
  select.listen('MDCSelect:change', () => {
	document.getElementById(hiddenFieldId).value = select.value
  })
  return select
}

/**
 * Attach to open details of a location. Replace the value from the datafield with the part of {datafield} in url
 *
 * @param {String} selector selector
 * @param {String} url URL
 * @param {String} datafield datafield
 */
function attachOpenDetails(selector, url, datafield) {
  let elements = document.querySelectorAll(selector)
  for (let i = 0, len = elements.length; i < len; i++) {
    elements[i].onclick = () => {
      window.location.href = url.replace('{' + datafield + '}', elements[i].dataset[datafield])
    }
  }
}

/**
 * Attach to open details of a location in a new window. Replace the value from the datafield with the part of {datafield} in url
 *
 * @param {String} selector selector
 * @param {String} url URL
 * @param {String} datafield datafield
 */
function attachOpenDetailsNewWindow(selector, url, datafield) {
  let elements = document.querySelectorAll(selector)
  for (let i = 0, len = elements.length; i < len; i++) {
    elements[i].onclick = () => {
      let win = window.open(url.replace('{' + datafield + '}', elements[i].dataset[datafield]), '_blank');
      win.focus();
    }
  }
}

/**
 * Attach to open details of a location, if valid datafield is valid. Replace the value from the datafield with the part of {datafield} in url
 *
 * @param {String} selector selector
 * @param {String} url URL
 * @param {String} datafield datafield
 * @param {String} validDatafield kind datafield
 * @param {String} valid click action when kindDatafield is kind
 */
function attachOpenDetailsWhenValid(selector, url, datafield, validDatafield, valid) {
  let elements = document.querySelectorAll(selector)
  for (let i = 0, len = elements.length; i < len; i++) {
	if (elements[i].dataset[validDatafield] === valid) {
      elements[i].onclick = () => {
        window.location.href = url.replace('{' + datafield + '}', elements[i].dataset[datafield])
      }
    }
  }
}

/**
 * Attach to open details of a location by an ID. Replace the value from the datafield1 with the part of {datafield1} and the datafield2 with the part of {datafield2} in url
 *
 * @param {String} selector selector
 * @param {String} url URL
 * @param {String} datafield1 datafield
 * @param {String} datafield2 datafield
 */
function attachOpenDetailsTwoIds(selector, url, datafield1, datafield2) {
  let elements = document.querySelectorAll(selector)
  for (let i = 0, len = elements.length; i < len; i++) {
	elements[i].onclick = () => {
		window.location.href = url.replace('{' + datafield1 + '}', elements[i].dataset[datafield1]).replace('{' + datafield2 + '}', elements[i].dataset[datafield2])
    }
  }
}

/**
 * Attach to open details of a location by an ID. Replace the value from the datafield1 with the part of {datafield1} and the datafield2 with the part of {datafield2} and the datafield3 with the part of {datafield3} in url
 *
 * @param {String} selector selector
 * @param {String} url URL
 * @param {String} datafield1 datafield
 * @param {String} datafield2 datafield
 *  @param {String} datafield3 datafield
 */
function attachOpenDetailsFourIds(selector, url, datafield1, datafield2, datafield3, datafield4) {
  let elements = document.querySelectorAll(selector)
  for (let i = 0, len = elements.length; i < len; i++) {
	elements[i].onclick = () => {
		window.location.href = url.replace('{' + datafield1 + '}', elements[i].dataset[datafield1]).replace('{' + datafield2 + '}', elements[i].dataset[datafield2]).replace('{' + datafield3 + '}', elements[i].dataset[datafield3]).replace('{' + datafield4 + '}', elements[i].dataset[datafield4])
    }
  }
}

/**
 * Attach a snackbar and open an url on success
 *
 * @param {String} id ID
 * @param {String} url URL
 * @param {String} successClass success css class
 * @param {String} errorClass error css class
 */
function attachSnackbarById(id, url, successClass, errorClass) {
  let elem = document.getElementById(id)
  let snackbar = mdc.snackbar.MDCSnackbar.attachTo(elem)
  snackbar.listen('MDCSnackbar:closed', () => {
    if (elem.classList.contains(successClass)) {
      window.location.href = url
    }
    elem.classList.remove(errorClass)
    elem.classList.remove(successClass)
  })
  return snackbar
}

/**
 * Attach to open details of a location by an ID. Replace the value from the datafield with the part of {datafield} in url
 *
 * @param {String} selector selector
 * @param {String} url URL
 * @param {String} datafield datafield
 */
function attachOpenDetailsById(id, url, datafield) {
  let elem = document.getElementById(id)
  elem.onclick = () => {
      window.location.href = url.replace('{' + datafield + '}', elem.dataset[datafield])
  }
}

/**
 * Attach to open details of a location by an ID. Replace the value from the datafield with the part of {datafield} in url, when datafield is the same
 *
 * @param {String} selector selector
 * @param {String} url URL
 * @param {String} datafield datafield
 * @param {String} validDatafield kind datafield
 * @param {String} valid click action when kindDatafield is kind
 */
function attachOpenDetailsWhenValidById(id, url, datafield, validDatafield, valid) {
  let elem = document.getElementById(id)
    if (elem.dataset[validDatafield] === valid) {
      elem.onclick = () => {
      window.location.href = url.replace('{' + datafield + '}', elem.dataset[datafield])
    }
  }
}

/**
 * Opens a popup with an url
 *
 * @param {String} url URL
 */
function popup(url) {
  let id = new Date().getTime()
  let fwidth = 800
  let fheight = 600
  let fleft = (window.screen.width - fwidth) / 2
  let ftop = (window.screen.height - fheight) / 2

  window.open(url, id, 'toolbar=0, scrollbars=1, location=0, statusbar=0, menubar=0, resizable=1, width=' + fwidth + ', height = ' + fheight + ', left = ' + fleft + ', top = ' + ftop)
}

/**
 * fetch an url with method delete
 *
 * @param {String} url URL
 */
function deleteData(url) {
  return fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  }).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      let error = new Error(response.statusText)
      error.response = response
      throw error
    }
  })
}

/**
 * fetch an url with method get
 *
 * @param {String} url URL
 */
function getData(url) {
  return fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  }).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      let error = new Error(response.statusText)
      error.response = response
      throw error
    }
  })
}
